import React, { Fragment, useState, useEffect } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Saas/saas.style';
import Navbar from '../containers/Saas/Navbar';
import TimeLineSection from '../containers/Saas/TimeLineSection';
import Footer from '../containers/Saas/Footer';
import CallToActionBottom from '../containers/Saas/TrialSection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
import Layout from '../components/layout';
import ModalForm from '../containers/Saas/contactModal';

export default () => {
  if (typeof window === `undefined`) {
    return <></>;
  }

  useEffect(() => {
    if (window) {
      var hash = window.location.hash;
      if (hash) {
        setTimeout(() => {
          hash = hash.slice(1, hash.length);
          var element = document.getElementById(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1500);
      }
    }
  }, []);
  const [openDemoModal, setOpenDemoModal] = useState(false);

  function handleOpenDemoModal() {
    setOpenDemoModal(true);
  }
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title="SalesOcean" />
        <ResetCSS />
        <GlobalStyle />
        <Layout>
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <TimeLineSection handleOpenDemoModal={handleOpenDemoModal} />

            <CallToActionBottom />
            <Footer />
            <ModalForm open={openDemoModal} setOpen={setOpenDemoModal} />
          </ContentWrapper>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
