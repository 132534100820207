import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Container from 'common/src/components/UI/Container';
import CustomizedTimeline from './timeline';

const TimelineSection = ({ sectionWrapper }) => {
  return (
    <Box {...sectionWrapper}>
      <Container>
        <CustomizedTimeline />
      </Container>
    </Box>
  );
};

TimelineSection.propTypes = {
  sectionWrapper: PropTypes.object,
};

TimelineSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: '150px',
    pb: ['60px', '80px', '80px', '80px'],
  },
};

export default TimelineSection;
