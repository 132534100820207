import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { timelineConst } from './timelineConst';
import { green, amber, blue } from '@material-ui/core/colors';
import Badge from '@material-ui/core/Badge';
const useStyles = makeStyles(theme => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  dotStyle: {
    width: '40px',
    height: '40px',
    '& > svg': {
      width: '30px',
      height: '30px',
    },
  },
}));

export default function CustomizedTimeline() {
  const classes = useStyles();

  return (
    <Timeline align="alternate">
      {timelineConst.map((con, i) => (
        <TimelineItem
          key={`this-timeline-time-${i}`}
          style={{ minHeight: '180px' }}
        >
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              {con.time}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              style={{
                backgroundColor: con.finished
                  ? green[500]
                  : con.underdevelopment
                  ? amber[500]
                  : blue[500],
              }}
              className={classes.dotStyle}
            >
              {con.icon}
            </TimelineDot>
            {i !== timelineConst.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Badge
              badgeContent={con.badge}
              anchorOrigin={{
                vertical: 'top',
                horizontal: con.badgePosition || 'right',
              }}
              color={con.badgeColor || 'secondary'}
            >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h4">
                  {con.name}
                </Typography>
                <Typography variant="body2">{con.description}</Typography>
              </Paper>
            </Badge>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
