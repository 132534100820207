import React from 'react';
import { ic_trending_up } from 'react-icons-kit/md/ic_trending_up';
import Icon from 'react-icons-kit';
import { ic_email } from 'react-icons-kit/md/ic_email';
import { ic_dashboard } from 'react-icons-kit/md/ic_dashboard';
import { ic_perm_data_setting } from 'react-icons-kit/md/ic_perm_data_setting';
import { ic_call_split } from 'react-icons-kit/md/ic_call_split';
import { ic_share } from 'react-icons-kit/md/ic_share';
import { ic_mood } from 'react-icons-kit/md/ic_mood';

export const timelineConst = [
  {
    name: 'Pipedrive intregration',
    time: 'December',
    description:
      'Bi-directional integration with Pipedrive to synchronize contacts and account across the two system. Or, optionally, a push synchronization where users manually push updates to Pipedrive.',
    icon: <Icon size={30} icon={ic_share} />,
    finished: true,
    badge: 'BETA',
  },
  {
    name: 'New analytics and insights',
    time: 'Mid Januari',
    description:
      'Indept data insight in products, campaigns and sequences with automated weekly data snapshots for deeper analytics. Moreover, it will be possible to provide more meaning to stages.',
    icon: <Icon size={30} icon={ic_trending_up} />,
    underdevelopment: true,
    badge: 'In progress',
    badgePosition: 'left',
    badgeColor: 'primary',
  },
  {
    name: 'Manage your mailbox per product',
    time: 'Early Januari',
    description:
      'Manage mailboxes per product instead of just users. This allows teams and specially agencies to work effeciently across products/clients without the needs to switch user accounts.',
    icon: <Icon size={30} icon={ic_email} />,
    untouched: true,
  },
  {
    name: 'New dashboard',
    time: 'Late Januari',
    description:
      'Brand new dashboard with as mission "one view, full control". This dashboard will help users to work more efficiently without the need to more across the application too much.',
    icon: <Icon size={30} icon={ic_dashboard} />,
    untouched: true,
  },
  {
    name: 'Complex sequences',
    time: 'Early Februari',
    description:
      'Complex mutli thread sequences will offer much more than single thread sequences to enhance engagement and build a more personalized strategy. Moreover, this feature will include A/B testing.',
    icon: <Icon size={30} icon={ic_call_split} />,
    untouched: true,
  },
  {
    name: 'Triggers',
    time: 'Mid Februari',
    description:
      'Triggers can help users to automate activites. Triggers are based on certain events which will automatically update data in SalesOcean or start new events. We will expand this feature slowely.',
    icon: <Icon size={30} icon={ic_perm_data_setting} />,
    untouched: true,
  },
  {
    name: 'SalesForce integration',
    time: 'Late Februari',
    description:
      'Native bi-directional SalesForce integration. This integration will synchronize accounts and contacts across systems.',
    icon: <Icon size={30} icon={ic_share} />,
    untouched: true,
  },
  {
    name: 'Zapier integration',
    time: 'Early March',
    description:
      'Native bi-directional Zapier integration. This integration will synchronize accounts and contacts across systems.',
    icon: <Icon size={30} icon={ic_share} />,
    untouched: true,
  },

  {
    name: 'More comming soon',
    time: 'Any time',
    description:
      'Any suggestions?  Feel free to let us know at info@salesocean.io',
    icon: <Icon size={30} icon={ic_mood} />,
    finished: true,
  },
];
